<template>
  <div>
    <h4 class="mb-2">Update template</h4>
    <b-form class="mb-2">
      <b-row>
        <!-- Field: Related project name -->
        <b-col cols="12" md="4">
          <b-form-group label="Related Project" label-for="project-name">
          <b-form-select
            v-model="templateData.project"
            :options="projectsOptions"
            value-field="id"
            text-field="name_uuid"
            place-holder="d"
          ></b-form-select>
        </b-form-group>
        </b-col>

        <!-- Field: Comment -->
        <b-col cols="12" md="4">
          <b-form-group label="Comment" label-for="comment">
            <b-form-textarea
              id="textarea"
              v-model="templateData.comment"
              placeholder="Comment"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
            <!-- Type Market -->
          <b-form-radio-group
            v-model="selectedRadioTypeMarket"
            :options="optionsRadioTypeMarket"
            class="mb-1"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
          <b-form-group
            label="Chose any of existing Type Markets" 
            label-for="project-name"
            v-if="selectedRadioTypeMarket === 'existing'"
          >
            <b-form-select
              v-model="templateData.type_market"
              :options="typeMarketOptions"
              required
              value-field="value"
            >
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Or write new Type Market"
            label-for="project-name"
            v-if="selectedRadioTypeMarket === 'new'"
          >
            <b-form-input
              v-model="templateData.type_market"
              placeholder="Write new Type Market"
              type="text" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!-- {{templateData}} -->
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="sendRequestToUpdateTemplate"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      @click="resetTemplateData"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import store from "@/store";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { errorMessage } from "@/auth/utils";


export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
  },
  props: {
    templateData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionsRadioTypeMarket: ['existing', 'new'],
      selectedRadioTypeMarket: "existing"
    };
  },
  computed: {
    projectsOptions() {
      let internal_projects = []

      if (this.$store.state.Projects.projects) {
        internal_projects = this.$store.state.Projects.projects.filter((project) =>
        project.project_type === 'internal');
      }

      for (let i = 0; i < internal_projects.length; i++) {
        internal_projects[i].name_uuid = internal_projects[i].name + ' (' + internal_projects[i].uuid + ')'
      } // Create name_uuid option to use in b-form-select

      return internal_projects
    },
    typeMarketOptions() {
      let typeMarkets = this.$store.state.Templates.typeMarkets
      return typeMarkets
    },
  },
  setup() {
    store.dispatch("Templates/GET_ALL_TEMPLATES");
    store.dispatch("Projects/GET_MY_PROJECTS");
  },
  methods: {
    sendRequestToUpdateTemplate() {
      store
        .dispatch("Template/updateTemplate", {
          templateID: router.currentRoute.params.templateID,
          project: this.templateData.project,
          comment: this.templateData.comment,
          type_market: this.templateData.type_market
        })
        .then(() => {
          this.$router.push("/templates");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "You have successfully update this project",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data?.detail
                ? err.response.data?.detail
                : "Please try again",
            },
          });
        });
    },
    resetTemplateData() {
      this.templateData.name = "";
      this.templateData.comment = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
