<template>
  <component :is="templateData === undefined ? 'div' : 'b-card'">
    <b-tabs v-if="templateData" pills>
      <!-- Tab: Account -->
      <template-edit-tab-account :template-data="templateData" class="mt-2 pt-75" />
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import TemplateEditTabAccount from "./TemplateEditTabAccount.vue";
import TemplateEditTabInformation from "./TemplateEditTabInformation.vue";
import TemplateEditTabSocial from "./TemplateEditTabSocial.vue";
import { errorMessage } from "@/auth/utils";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    TemplateEditTabAccount,
    TemplateEditTabInformation,
    TemplateEditTabSocial,
  },
  setup() {
    const templateData = ref(null);

    store
      .dispatch("Template/loadTemplate", router.currentRoute.params.templateID)
      .then((response) => {
        templateData.value = response.data;
      })
      .catch((error) => {
        errorMessage(this.$toast, error);
      });

    return {
      templateData,
    };
  },
};
</script>

<style>
</style>
